import * as React from "react"
import { useEffect, useState } from "react"
import { createTagString } from "../../../services/tagsHelper"
import "../../../css/bootstrap-tagsinput.css"
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap"

const Tags = ({ tags, setTags, tempTags, setTempTags, showTags, setShowTags }) => {
  const [uiBlockClasses, setUiBlockClasses] = useState("CD_PrivateTag_Container"),
    [editBlockClasses, setEditBlockClasses] = useState("CD_PrivateTag_Editor position-relative d-none"),
    [plainTags, setPlainTags] = useState(createTagString(tags)),
    [newTag, setNewTag] = useState(""),
    [addTag, setAddTag] = useState(false),
    [updateTags, setUpdateTags] = useState(false),
    [restoreTags, setRestoreTags] = useState(false),
    [removeTagIndex, setRemoveTagIndex] = useState(null)

  const [assignBlock, setAssignBlock] = useState("")
  const [tagColor, setTagColor] = useState("")
  const [assignTag, setAssignTag] = useState([])
  const [assignTags, setAssignTags] = useState([])
  const [assignTagsScore, setAssignTagsScore] = useState(null)
  const [tagPrivacy, setTagPrivacy] = useState("bi bi-incognito")

  const TAG_PRIVACY_OPTIONS = [
    {
      lable: "Me Only",
      type: "MeOnly",
      icon: "bi bi-incognito",
    },
    {
      lable: "Company Only",
      type: "CompanyOnly",
      icon: "bi bi-building",
    },
    {
      lable: "Everyone with Access",
      type: "Everyone",
      icon: "bi bi-globe2",
    },
  ]

  const handleAssignBlock = tagName => {
    setAssignBlock(tagName)
  }

  const showEditTagBlock = () => {
    setUiBlockClasses("CD_PrivateTag_Container d-none")
    setEditBlockClasses("CD_PrivateTag_Editor position-relative")
  }

  const hideEditTagBlock = () => {
    setUiBlockClasses("CD_PrivateTag_Container")
    setEditBlockClasses("CD_PrivateTag_Editor position-relative d-none")
  }

  const handleTagColor = (tagType, tag, tags) => {
    setAssignTags(tags)
    setAssignTag([tag])
    let type = tagType
    switch (type) {
      case "blue":
        setTagColor("blue")
        break
      case "pink":
        setTagColor("pink")
        break
      case "beige":
        setTagColor("beige")
        break
      case "green":
        setTagColor("green")
        break
      case "gray":
        setTagColor("gray")
        break
      default:
        setTagColor("blue")
    }
  }

  useEffect(() => {
    if (showTags) {
      showEditTagBlock()
    } else {
      hideEditTagBlock()
    }

    if (assignTag.length > 0) {
      assignTag[0].color = tagColor
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
  }, [tagColor, showTags, showEditTagBlock, hideEditTagBlock])

  const changeTagScore = (e, tag, tags) => {
    setAssignTagsScore(e.target.value)
    setAssignTags(tags)
    setAssignTag([tag])
  }

  const handleTagScore = assignTagsScore => {
    if (assignTagsScore !== null) {
      if (assignTag.length > 0) {
        assignTag[0].score = assignTagsScore
      }
      assignTag.forEach(elements => {
        const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
        if (tagNames > -1) {
          assignTags[tagNames] = elements
        } else {
          assignTags = assignTags.push(elements)
        }
      })
      if (assignTag.length > 0) {
        // setAssignBlock("")
        setUpdateTags(true)
      }
    }
  }

  const handleTagPrivacy = (icon, tag, tags) => {
    setTagPrivacy(icon)
    setAssignTags(tags)
    setAssignTag([tag])
  }

  useEffect(() => {
    if (assignTag.length > 0) {
      assignTag[0].icon = tagPrivacy
    }
    assignTag.forEach(elements => {
      const tagNames = assignTags.findIndex(tags => tags.tag === elements.tag)
      if (tagNames > -1) {
        assignTags[tagNames] = elements
      } else {
        assignTags = assignTags.push(elements)
      }
    })
    if (assignTag.length > 0) {
      // setAssignBlock("")
      setUpdateTags(true)
    }
  }, [tagPrivacy])

  useEffect(() => {
    if (addTag) {
      setAddTag(false)
      let filterTag = tempTags.filter(tags => {
        return tags.tag === newTag
      })
      if (filterTag.length === 0) {
        setTempTags([
          ...tempTags,
          {
            tag: newTag,
            score: 0,
            color: "blue",
            icon: "",
          },
        ])
        setNewTag("")
      }
    }

    if (removeTagIndex) {
      setRemoveTagIndex(null)
      let tempTag = []

      tempTags.map((tagArray, arrayIndex) => {
        if (parseInt(removeTagIndex) !== arrayIndex) {
          tempTag = [...tempTag, tagArray]
        }
        if (arrayIndex === tempTags.length - 1) {
          setTempTags([...tempTag])
        }
      })
    }

    if (updateTags) {
      setUpdateTags(false)
      setTags([...tempTags])
    }

    if (restoreTags) {
      setRestoreTags(false)
      setTempTags([...tags])
    }
  })

  return (
    <React.Fragment>
      <div className={uiBlockClasses}>
        <div className="CD_PrivateTag_Container d-inline-block me-5 text-start text-lg-end">
          <div className="SwiftCloudTableTags">
            <ul>
              {tempTags.length > 0 &&
                tempTags.map((tagDetail, tagIndex) => {
                  return (
                    <li
                      key={tagIndex}
                      className={assignBlock == tagDetail ? `tag-${tagColor}` : `tag-${tagDetail.color}`}
                      onMouseEnter={() => handleAssignBlock(tagDetail)}
                      onMouseLeave={() => setAssignBlock("")}
                    >
                      <a key={tagIndex} href="#" data-tagname="Designer">
                        {tagDetail.tag}{" "}
                        {/* {assignTagsScore === null ? `(${tagDetail.score})` : `(${assignTagsScore})`} */}
                        {tagDetail.score == 0 ? "" : ` (${tagDetail.score})`} <i className={tagDetail.icon} />{" "}
                      </a>
                      {assignBlock == tagDetail && (
                        <div className="SwiftCloudTagScore">
                          <div className="mb-2 SwiftCloudTagScoreType">
                            <a
                              className="SwiftCloudTagScoreBlue"
                              onClick={() => {
                                handleTagColor("blue", tagDetail, tempTags)
                              }}
                              data-type="blue"
                            />
                            <a
                              className="SwiftCloudTagScorePink"
                              onClick={() => {
                                handleTagColor("pink", tagDetail, tempTags)
                              }}
                              data-type="pink"
                            />
                            <a
                              className="SwiftCloudTagScoreBeige"
                              onClick={() => handleTagColor("beige", tagDetail, tempTags)}
                              data-type="beige"
                            />
                            <a
                              className="SwiftCloudTagScoreGreen"
                              onClick={() => handleTagColor("green", tagDetail, tempTags)}
                              data-type="green"
                            />
                            <a
                              className="SwiftCloudTagScoreGray"
                              onClick={() => handleTagColor("gray", tagDetail, tempTags)}
                              data-type="gray"
                            />

                            <Dropdown className="dropdown SwiftCloudTagPrivacyDropdown">
                              <Dropdown.Toggle
                                className="btn dropdown-toggle no-dropdown-arrow"
                                type="button"
                                variant=""
                              >
                                <i className="bi bi-incognito" />
                              </Dropdown.Toggle>
                              {/* <button

                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                </button> */}
                              <Dropdown.Menu as="ul">
                                {TAG_PRIVACY_OPTIONS.map((privacyMode, index) => {
                                  return (
                                    <li key={index}>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleTagPrivacy(privacyMode.icon, tagDetail, tempTags)
                                        }
                                        // className="dropdown-item"
                                        value={privacyMode.type}
                                      >
                                        <i className={privacyMode.icon} /> {privacyMode.lable}
                                      </Dropdown.Item>
                                    </li>
                                  )
                                })}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <span>Assign Tag Score / Value</span>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control tagScoreInput"
                              value={assignTagsScore === null ? tagDetail.score : assignTagsScore}
                              onChange={task => changeTagScore(task, tagDetail, tempTags)}
                              onKeyUp={task => {
                                if (task.key === "Enter") {
                                  handleTagScore(assignTagsScore, tagDetail, tags)
                                }
                              }}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-success btnSaveTagScore"
                              onClick={() => handleTagScore(assignTagsScore, tagDetail, tempTags)}
                              onMouseLeave={() => handleTagScore(assignTagsScore, tagDetail, tags)}
                            >
                              <i className="bi bi-check2" />
                            </button>
                          </div>
                        </div>
                      )}
                    </li>
                  )
                })}
            </ul>

            {/* {tempTags.length === 0 && (
              <a
                onClick={showEditTagBlock}
                className="btn btnEditPrivateTags btnAddNewGreenDashed mt-2"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Add Tags"
              >
                <i className="bi bi-plus-lg" />
              </a>
            )}
            {tempTags.length > 0 && (
              <a
                onClick={showEditTagBlock}
                className="btn btnEditPrivateTags"
                data-bs-toggle="tooltip"
                data-placement="top"
                title="Edit Tags"
              >
                <i className="bi bi-pencil-fill" />
              </a>
            )} */}
          </div>
        </div>
      </div>
      <div className={editBlockClasses}>
        <div className="bootstrap-tagsinput mt-1">
          {tempTags.length > 0 &&
            tempTags.map((tagDetail, tagIndex) => (
              <span className="tag label label-info" key={tagDetail.tag}>
                {tagDetail.tag}
                <span data-role="remove" onClick={() => setRemoveTagIndex(`${tagIndex}`)} />
                <span data-role="drag" />
              </span>
            ))}
          <input
            type="text"
            placeholder="Enter a #tag then click Tab"
            size="27"
            className="tagsInputBox"
            onKeyDown={e => {
              if (e.code === "Tab" || e.code === "Enter" || e.which === 13) {
                if (tempTags.length === 0) {
                  setAddTag(false)
                  setTempTags([
                    ...tempTags,
                    {
                      tag: newTag,
                      score: 0,
                      color: "blue",
                    },
                  ])
                  setNewTag("")
                } else {
                  setAddTag(true)
                }
              }
            }}
            value={newTag}
            // defaultValue={newTag}
            onChange={e => {
              setNewTag(e.target.value)
            }}
          />
        </div>
        <input type="text" className="form-control d-none" defaultValue={plainTags} />

        <OverlayTrigger overlay={<Tooltip>Save Tags</Tooltip>}>
          <button
            type="button"
            className="btnSaveGroupTags"
            onClick={() => {
              hideEditTagBlock()
              setShowTags(false)
            }}
          >
            <i className="bi bi-check-lg" />
          </button>
        </OverlayTrigger>
      </div>
    </React.Fragment>
  )
}

export default Tags
